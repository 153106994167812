import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _73bdfe9f = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _0d5b0bfc = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _8bde2f90 = () => interopDefault(import('../pages/c/index.vue' /* webpackChunkName: "pages/c/index" */))
const _e7c4f84c = () => interopDefault(import('../pages/clusters/index.vue' /* webpackChunkName: "pages/clusters/index" */))
const _f274479a = () => interopDefault(import('../pages/design-system/index.vue' /* webpackChunkName: "pages/design-system/index" */))
const _9c9db6d0 = () => interopDefault(import('../pages/fail-whale.vue' /* webpackChunkName: "pages/fail-whale" */))
const _6f88bc86 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _772e3442 = () => interopDefault(import('../pages/prefs.vue' /* webpackChunkName: "pages/prefs" */))
const _7b3c4d24 = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _80b50de4 = () => interopDefault(import('../pages/account/create-key.vue' /* webpackChunkName: "pages/account/create-key" */))
const _f58fe9c0 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _6eda6163 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _4892ac34 = () => interopDefault(import('../pages/auth/setup.vue' /* webpackChunkName: "pages/auth/setup" */))
const _4f01c4d2 = () => interopDefault(import('../pages/auth/verify.vue' /* webpackChunkName: "pages/auth/verify" */))
const _697af500 = () => interopDefault(import('../pages/design-system/form-controls.vue' /* webpackChunkName: "pages/design-system/form-controls" */))
const _77b800f5 = () => interopDefault(import('../pages/design-system/provider-images.vue' /* webpackChunkName: "pages/design-system/provider-images" */))
const _d4ccc83c = () => interopDefault(import('../pages/rio/mesh.vue' /* webpackChunkName: "pages/rio/mesh" */))
const _40bb3c1a = () => interopDefault(import('../pages/design-system/page-examples/create.vue' /* webpackChunkName: "pages/design-system/page-examples/create" */))
const _504126c8 = () => interopDefault(import('../pages/design-system/page-examples/detail.vue' /* webpackChunkName: "pages/design-system/page-examples/detail" */))
const _3d0f4756 = () => interopDefault(import('../pages/design-system/page-examples/list.vue' /* webpackChunkName: "pages/design-system/page-examples/list" */))
const _1dc1d7f0 = () => interopDefault(import('../pages/c/_cluster/index.vue' /* webpackChunkName: "pages/c/_cluster/index" */))
const _72708442 = () => interopDefault(import('../pages/docs/_doc.vue' /* webpackChunkName: "pages/docs/_doc" */))
const _2ef42c2f = () => interopDefault(import('../pages/c/_cluster/apps/index.vue' /* webpackChunkName: "pages/c/_cluster/apps/index" */))
const _df139836 = () => interopDefault(import('../pages/c/_cluster/auth/index.vue' /* webpackChunkName: "pages/c/_cluster/auth/index" */))
const _6a5d9a02 = () => interopDefault(import('../pages/c/_cluster/backup/index.vue' /* webpackChunkName: "pages/c/_cluster/backup/index" */))
const _4e317e46 = () => interopDefault(import('../pages/c/_cluster/cis/index.vue' /* webpackChunkName: "pages/c/_cluster/cis/index" */))
const _7fb17a88 = () => interopDefault(import('../pages/c/_cluster/ecm/index.vue' /* webpackChunkName: "pages/c/_cluster/ecm/index" */))
const _64298688 = () => interopDefault(import('../pages/c/_cluster/explorer/index.vue' /* webpackChunkName: "pages/c/_cluster/explorer/index" */))
const _41be43f2 = () => interopDefault(import('../pages/c/_cluster/fleet/index.vue' /* webpackChunkName: "pages/c/_cluster/fleet/index" */))
const _7680bc0c = () => interopDefault(import('../pages/c/_cluster/gatekeeper/index.vue' /* webpackChunkName: "pages/c/_cluster/gatekeeper/index" */))
const _49c01969 = () => interopDefault(import('../pages/c/_cluster/istio/index.vue' /* webpackChunkName: "pages/c/_cluster/istio/index" */))
const _2c51d774 = () => interopDefault(import('../pages/c/_cluster/legacy/index.vue' /* webpackChunkName: "pages/c/_cluster/legacy/index" */))
const _804c8950 = () => interopDefault(import('../pages/c/_cluster/logging/index.vue' /* webpackChunkName: "pages/c/_cluster/logging/index" */))
const _29485b08 = () => interopDefault(import('../pages/c/_cluster/longhorn/index.vue' /* webpackChunkName: "pages/c/_cluster/longhorn/index" */))
const _27e57626 = () => interopDefault(import('../pages/c/_cluster/manager/index.vue' /* webpackChunkName: "pages/c/_cluster/manager/index" */))
const _a6540a76 = () => interopDefault(import('../pages/c/_cluster/mcapps/index.vue' /* webpackChunkName: "pages/c/_cluster/mcapps/index" */))
const _5be45105 = () => interopDefault(import('../pages/c/_cluster/monitoring/index.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/index" */))
const _f513d8c0 = () => interopDefault(import('../pages/c/_cluster/settings/index.vue' /* webpackChunkName: "pages/c/_cluster/settings/index" */))
const _0246c3cb = () => interopDefault(import('../pages/c/_cluster/apps/charts/index.vue' /* webpackChunkName: "pages/c/_cluster/apps/charts/index" */))
const _86cdc3fc = () => interopDefault(import('../pages/c/_cluster/auth/config/index.vue' /* webpackChunkName: "pages/c/_cluster/auth/config/index" */))
const _8c8e925a = () => interopDefault(import('../pages/c/_cluster/auth/roles/index.vue' /* webpackChunkName: "pages/c/_cluster/auth/roles/index" */))
const _007567cd = () => interopDefault(import('../pages/c/_cluster/explorer/HardwareResourceGauge.vue' /* webpackChunkName: "pages/c/_cluster/explorer/HardwareResourceGauge" */))
const _d754e574 = () => interopDefault(import('../pages/c/_cluster/explorer/members/index.vue' /* webpackChunkName: "pages/c/_cluster/explorer/members/index" */))
const _467babfc = () => interopDefault(import('../pages/c/_cluster/explorer/ResourceSummary.vue' /* webpackChunkName: "pages/c/_cluster/explorer/ResourceSummary" */))
const _c0821ff0 = () => interopDefault(import('../pages/c/_cluster/explorer/tools/index.vue' /* webpackChunkName: "pages/c/_cluster/explorer/tools/index" */))
const _73ca6961 = () => interopDefault(import('../pages/c/_cluster/gatekeeper/constraints/index.vue' /* webpackChunkName: "pages/c/_cluster/gatekeeper/constraints/index" */))
const _45e53cd0 = () => interopDefault(import('../pages/c/_cluster/legacy/project/index.vue' /* webpackChunkName: "pages/c/_cluster/legacy/project/index" */))
const _2c605f7a = () => interopDefault(import('../pages/c/_cluster/manager/cloudCredential/index.vue' /* webpackChunkName: "pages/c/_cluster/manager/cloudCredential/index" */))
const _5dd09f90 = () => interopDefault(import('../pages/c/_cluster/monitoring/monitor/index.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/monitor/index" */))
const _6a2e3853 = () => interopDefault(import('../pages/c/_cluster/monitoring/route-receiver/index.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/route-receiver/index" */))
const _f2aa5856 = () => interopDefault(import('../pages/c/_cluster/settings/brand.vue' /* webpackChunkName: "pages/c/_cluster/settings/brand" */))
const _deade652 = () => interopDefault(import('../pages/c/_cluster/apps/charts/chart.vue' /* webpackChunkName: "pages/c/_cluster/apps/charts/chart" */))
const _0b6d4058 = () => interopDefault(import('../pages/c/_cluster/apps/charts/install.vue' /* webpackChunkName: "pages/c/_cluster/apps/charts/install" */))
const _2518636b = () => interopDefault(import('../pages/c/_cluster/auth/group.principal/assign-edit.vue' /* webpackChunkName: "pages/c/_cluster/auth/group.principal/assign-edit" */))
const _8b8738e2 = () => interopDefault(import('../pages/c/_cluster/legacy/project/pipelines.vue' /* webpackChunkName: "pages/c/_cluster/legacy/project/pipelines" */))
const _77de82db = () => interopDefault(import('../pages/c/_cluster/manager/cloudCredential/create.vue' /* webpackChunkName: "pages/c/_cluster/manager/cloudCredential/create" */))
const _f810c5a4 = () => interopDefault(import('../pages/c/_cluster/monitoring/monitor/create.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/monitor/create" */))
const _034d1ccb = () => interopDefault(import('../pages/c/_cluster/monitoring/route-receiver/create.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/route-receiver/create" */))
const _3458d599 = () => interopDefault(import('../pages/c/_cluster/explorer/tools/pages/_page.vue' /* webpackChunkName: "pages/c/_cluster/explorer/tools/pages/_page" */))
const _7f0a8e2c = () => interopDefault(import('../pages/c/_cluster/auth/config/_id.vue' /* webpackChunkName: "pages/c/_cluster/auth/config/_id" */))
const _86ef5952 = () => interopDefault(import('../pages/c/_cluster/legacy/pages/_page.vue' /* webpackChunkName: "pages/c/_cluster/legacy/pages/_page" */))
const _a2b2fd28 = () => interopDefault(import('../pages/c/_cluster/legacy/project/_page.vue' /* webpackChunkName: "pages/c/_cluster/legacy/project/_page" */))
const _9ee9f12a = () => interopDefault(import('../pages/c/_cluster/manager/cloudCredential/_id.vue' /* webpackChunkName: "pages/c/_cluster/manager/cloudCredential/_id" */))
const _3dfdcd37 = () => interopDefault(import('../pages/c/_cluster/manager/pages/_page.vue' /* webpackChunkName: "pages/c/_cluster/manager/pages/_page" */))
const _3f0bc2d4 = () => interopDefault(import('../pages/c/_cluster/mcapps/pages/_page.vue' /* webpackChunkName: "pages/c/_cluster/mcapps/pages/_page" */))
const _6af7990a = () => interopDefault(import('../pages/c/_cluster/monitoring/route-receiver/_id.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/route-receiver/_id" */))
const _51e74926 = () => interopDefault(import('../pages/c/_cluster/auth/roles/_resource/create.vue' /* webpackChunkName: "pages/c/_cluster/auth/roles/_resource/create" */))
const _1c244819 = () => interopDefault(import('../pages/c/_cluster/auth/roles/_resource/_id.vue' /* webpackChunkName: "pages/c/_cluster/auth/roles/_resource/_id" */))
const _680095b9 = () => interopDefault(import('../pages/c/_cluster/monitoring/monitor/_namespace/_id.vue' /* webpackChunkName: "pages/c/_cluster/monitoring/monitor/_namespace/_id" */))
const _f3141122 = () => interopDefault(import('../pages/c/_cluster/navlinks/_group.vue' /* webpackChunkName: "pages/c/_cluster/navlinks/_group" */))
const _a1630226 = () => interopDefault(import('../pages/c/_cluster/_product/index.vue' /* webpackChunkName: "pages/c/_cluster/_product/index" */))
const _17610a6d = () => interopDefault(import('../pages/c/_cluster/_product/namespaces.vue' /* webpackChunkName: "pages/c/_cluster/_product/namespaces" */))
const _55301867 = () => interopDefault(import('../pages/c/_cluster/_product/projectsnamespaces.vue' /* webpackChunkName: "pages/c/_cluster/_product/projectsnamespaces" */))
const _5e518d0b = () => interopDefault(import('../pages/c/_cluster/_product/_resource/index.vue' /* webpackChunkName: "pages/c/_cluster/_product/_resource/index" */))
const _d8d741da = () => interopDefault(import('../pages/c/_cluster/_product/_resource/create.vue' /* webpackChunkName: "pages/c/_cluster/_product/_resource/create" */))
const _32655633 = () => interopDefault(import('../pages/c/_cluster/_product/_resource/_id.vue' /* webpackChunkName: "pages/c/_cluster/_product/_resource/_id" */))
const _5483a29e = () => interopDefault(import('../pages/c/_cluster/_product/_resource/_namespace/_id.vue' /* webpackChunkName: "pages/c/_cluster/_product/_resource/_namespace/_id" */))
const _d9144d38 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/dashboard/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _73bdfe9f,
    name: "about"
  }, {
    path: "/account",
    component: _0d5b0bfc,
    name: "account"
  }, {
    path: "/c",
    component: _8bde2f90,
    name: "c"
  }, {
    path: "/clusters",
    component: _e7c4f84c,
    name: "clusters"
  }, {
    path: "/design-system",
    component: _f274479a,
    name: "design-system"
  }, {
    path: "/fail-whale",
    component: _9c9db6d0,
    name: "fail-whale"
  }, {
    path: "/home",
    component: _6f88bc86,
    name: "home"
  }, {
    path: "/prefs",
    component: _772e3442,
    name: "prefs"
  }, {
    path: "/support",
    component: _7b3c4d24,
    name: "support"
  }, {
    path: "/account/create-key",
    component: _80b50de4,
    name: "account-create-key"
  }, {
    path: "/auth/login",
    component: _f58fe9c0,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _6eda6163,
    name: "auth-logout"
  }, {
    path: "/auth/setup",
    component: _4892ac34,
    name: "auth-setup"
  }, {
    path: "/auth/verify",
    component: _4f01c4d2,
    name: "auth-verify"
  }, {
    path: "/design-system/form-controls",
    component: _697af500,
    name: "design-system-form-controls"
  }, {
    path: "/design-system/provider-images",
    component: _77b800f5,
    name: "design-system-provider-images"
  }, {
    path: "/rio/mesh",
    component: _d4ccc83c,
    name: "rio-mesh"
  }, {
    path: "/design-system/page-examples/create",
    component: _40bb3c1a,
    name: "design-system-page-examples-create"
  }, {
    path: "/design-system/page-examples/detail",
    component: _504126c8,
    name: "design-system-page-examples-detail"
  }, {
    path: "/design-system/page-examples/list",
    component: _3d0f4756,
    name: "design-system-page-examples-list"
  }, {
    path: "/c/:cluster",
    component: _1dc1d7f0,
    name: "c-cluster"
  }, {
    path: "/docs/:doc?",
    component: _72708442,
    name: "docs-doc"
  }, {
    path: "/c/:cluster/apps",
    component: _2ef42c2f,
    name: "c-cluster-apps"
  }, {
    path: "/c/:cluster/auth",
    component: _df139836,
    name: "c-cluster-auth"
  }, {
    path: "/c/:cluster/backup",
    component: _6a5d9a02,
    name: "c-cluster-backup"
  }, {
    path: "/c/:cluster/cis",
    component: _4e317e46,
    name: "c-cluster-cis"
  }, {
    path: "/c/:cluster/ecm",
    component: _7fb17a88,
    name: "c-cluster-ecm"
  }, {
    path: "/c/:cluster/explorer",
    component: _64298688,
    name: "c-cluster-explorer"
  }, {
    path: "/c/:cluster/fleet",
    component: _41be43f2,
    name: "c-cluster-fleet"
  }, {
    path: "/c/:cluster/gatekeeper",
    component: _7680bc0c,
    name: "c-cluster-gatekeeper"
  }, {
    path: "/c/:cluster/istio",
    component: _49c01969,
    name: "c-cluster-istio"
  }, {
    path: "/c/:cluster/legacy",
    component: _2c51d774,
    name: "c-cluster-legacy"
  }, {
    path: "/c/:cluster/logging",
    component: _804c8950,
    name: "c-cluster-logging"
  }, {
    path: "/c/:cluster/longhorn",
    component: _29485b08,
    name: "c-cluster-longhorn"
  }, {
    path: "/c/:cluster/manager",
    component: _27e57626,
    name: "c-cluster-manager"
  }, {
    path: "/c/:cluster/mcapps",
    component: _a6540a76,
    name: "c-cluster-mcapps"
  }, {
    path: "/c/:cluster/monitoring",
    component: _5be45105,
    name: "c-cluster-monitoring"
  }, {
    path: "/c/:cluster/settings",
    component: _f513d8c0,
    name: "c-cluster-settings"
  }, {
    path: "/c/:cluster/apps/charts",
    component: _0246c3cb,
    name: "c-cluster-apps-charts"
  }, {
    path: "/c/:cluster/auth/config",
    component: _86cdc3fc,
    name: "c-cluster-auth-config"
  }, {
    path: "/c/:cluster/auth/roles",
    component: _8c8e925a,
    name: "c-cluster-auth-roles"
  }, {
    path: "/c/:cluster/explorer/HardwareResourceGauge",
    component: _007567cd,
    name: "c-cluster-explorer-HardwareResourceGauge"
  }, {
    path: "/c/:cluster/explorer/members",
    component: _d754e574,
    name: "c-cluster-explorer-members"
  }, {
    path: "/c/:cluster/explorer/ResourceSummary",
    component: _467babfc,
    name: "c-cluster-explorer-ResourceSummary"
  }, {
    path: "/c/:cluster/explorer/tools",
    component: _c0821ff0,
    name: "c-cluster-explorer-tools"
  }, {
    path: "/c/:cluster/gatekeeper/constraints",
    component: _73ca6961,
    name: "c-cluster-gatekeeper-constraints"
  }, {
    path: "/c/:cluster/legacy/project",
    component: _45e53cd0,
    name: "c-cluster-legacy-project"
  }, {
    path: "/c/:cluster/manager/cloudCredential",
    component: _2c605f7a,
    name: "c-cluster-manager-cloudCredential"
  }, {
    path: "/c/:cluster/monitoring/monitor",
    component: _5dd09f90,
    name: "c-cluster-monitoring-monitor"
  }, {
    path: "/c/:cluster/monitoring/route-receiver",
    component: _6a2e3853,
    name: "c-cluster-monitoring-route-receiver"
  }, {
    path: "/c/:cluster/settings/brand",
    component: _f2aa5856,
    name: "c-cluster-settings-brand"
  }, {
    path: "/c/:cluster/apps/charts/chart",
    component: _deade652,
    name: "c-cluster-apps-charts-chart"
  }, {
    path: "/c/:cluster/apps/charts/install",
    component: _0b6d4058,
    name: "c-cluster-apps-charts-install"
  }, {
    path: "/c/:cluster/auth/group.principal/assign-edit",
    component: _2518636b,
    name: "c-cluster-auth-group.principal-assign-edit"
  }, {
    path: "/c/:cluster/legacy/project/pipelines",
    component: _8b8738e2,
    name: "c-cluster-legacy-project-pipelines"
  }, {
    path: "/c/:cluster/manager/cloudCredential/create",
    component: _77de82db,
    name: "c-cluster-manager-cloudCredential-create"
  }, {
    path: "/c/:cluster/monitoring/monitor/create",
    component: _f810c5a4,
    name: "c-cluster-monitoring-monitor-create"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/create",
    component: _034d1ccb,
    name: "c-cluster-monitoring-route-receiver-create"
  }, {
    path: "/c/:cluster/explorer/tools/pages/:page?",
    component: _3458d599,
    name: "c-cluster-explorer-tools-pages-page"
  }, {
    path: "/c/:cluster/auth/config/:id",
    component: _7f0a8e2c,
    name: "c-cluster-auth-config-id"
  }, {
    path: "/c/:cluster/legacy/pages/:page?",
    component: _86ef5952,
    name: "c-cluster-legacy-pages-page"
  }, {
    path: "/c/:cluster/legacy/project/:page",
    component: _a2b2fd28,
    name: "c-cluster-legacy-project-page"
  }, {
    path: "/c/:cluster/manager/cloudCredential/:id",
    component: _9ee9f12a,
    name: "c-cluster-manager-cloudCredential-id"
  }, {
    path: "/c/:cluster/manager/pages/:page?",
    component: _3dfdcd37,
    name: "c-cluster-manager-pages-page"
  }, {
    path: "/c/:cluster/mcapps/pages/:page?",
    component: _3f0bc2d4,
    name: "c-cluster-mcapps-pages-page"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/:id?",
    component: _6af7990a,
    name: "c-cluster-monitoring-route-receiver-id"
  }, {
    path: "/c/:cluster/auth/roles/:resource/create",
    component: _51e74926,
    name: "c-cluster-auth-roles-resource-create"
  }, {
    path: "/c/:cluster/auth/roles/:resource/:id?",
    component: _1c244819,
    name: "c-cluster-auth-roles-resource-id"
  }, {
    path: "/c/:cluster/monitoring/monitor/:namespace/:id?",
    component: _680095b9,
    name: "c-cluster-monitoring-monitor-namespace-id"
  }, {
    path: "/c/:cluster/navlinks/:group?",
    component: _f3141122,
    name: "c-cluster-navlinks-group"
  }, {
    path: "/c/:cluster/:product",
    component: _a1630226,
    name: "c-cluster-product"
  }, {
    path: "/c/:cluster/:product/namespaces",
    component: _17610a6d,
    name: "c-cluster-product-namespaces"
  }, {
    path: "/c/:cluster/:product/projectsnamespaces",
    component: _55301867,
    name: "c-cluster-product-projectsnamespaces"
  }, {
    path: "/c/:cluster/:product/:resource",
    component: _5e518d0b,
    name: "c-cluster-product-resource"
  }, {
    path: "/c/:cluster/:product/:resource/create",
    component: _d8d741da,
    name: "c-cluster-product-resource-create"
  }, {
    path: "/c/:cluster/:product/:resource/:id",
    component: _32655633,
    name: "c-cluster-product-resource-id"
  }, {
    path: "/c/:cluster/:product/:resource/:namespace/:id?",
    component: _5483a29e,
    name: "c-cluster-product-resource-namespace-id"
  }, {
    path: "/",
    component: _d9144d38,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
